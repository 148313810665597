<template>
  <div id="inventoryLabResultForm" class="form">
    <v-form v-if="loaded" ref="form" v-model="valid" lazy-validation>
      <v-text-field
        v-model="inventoryLabResult.inventoryId"
        label="Inventory ID"
        :required="false"
      />
      <v-text-field
        v-model="inventoryLabResult.sampleName"
        label="Sample Name"
        :required="true"
      />
      <v-text-field
        v-model="inventoryLabResult.quality"
        label="Quality"
        :required="true"
      />
      <v-text-field
        v-model="inventoryLabResult.averoutN"
        label="AveroutN"
        :required="true"
      />
      <v-text-field
        v-model="inventoryLabResult.program"
        label="Program"
        :required="true"
      />
      <v-text-field
        v-model="inventoryLabResult.technician"
        label="Technician"
        :required="true"
      />
      <v-text-field
        v-model="inventoryLabResult.testTime"
        label="Test Time"
        :required="true"
      />

      <v-checkbox label="Final Test" v-model="inventoryLabResult.finalTest"/>

      <div class="mt-4 d-flex justify-space-between">
        <v-btn @click="goBack"> Back </v-btn>
        <v-btn @click="handleSubmit" color="primary">
          {{ id ? "Update" : "Save" }}
        </v-btn>
        <span></span>
      </div>
    </v-form>
  </div>
</template>
    
<script>
import { getInventoryLabResult } from "@/services/inventoryLabResult";

import { mapActions } from "vuex";

export default {
  props: ["id"],
  data() {
    return {
      loaded: false,
      valid: false,
      inventoryLabResult: {
        relations: {},
        inventoryId: "",
        sampleName: "",
        quality: "",
        averoutN: "",
        program: "",
        technician: "",
        testTime: "",
        finalTest: null
      },
    };
  },
  created() {
    this.setInstance();
  },
  methods: {
    ...mapActions("inventoryLabResult", [
      "createInventoryLabResult",
      "updateInventoryLabResult",
    ]),
    ...mapActions("messages", [
      "addMessage",
      "addErrorMessage",
      "addSuccessMessage",
    ]),

    async handleSubmit() {
      if (this.id) {
        let inventoryLabResult = this.inventoryLabResult;
        delete inventoryLabResult.relations;

        this.updateInventoryLabResult(inventoryLabResult)
          .then((response) => {
            console.log(response);
            this.addSuccessMessage(
              "InventoryLabResult was updated successfully"
            );
            this.goBack();
          })
          .catch(() => {
            this.addErrorMessage(
              "Error updating InventoryLabResult, please try again later"
            );
          });
      } else {
        let inventoryLabResult = this.inventoryLabResult;
        delete inventoryLabResult.relations;

        this.createInventoryLabResult(inventoryLabResult)
          .then((response) => {
            console.log(response);
            this.addSuccessMessage(
              "InventoryLabResult was created successfully"
            );
            this.goBack();
          })
          .catch(() => {
            this.addErrorMessage(
              "Error creating InventoryLabResult, please try again later"
            );
          });
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    setInstance() {
      if (this.id) {
        this.inventoryLabResult.id = this.id;
        getInventoryLabResult(this.id).then((response) => {
          let instance = response.data;
          for (let property in instance) {
            if (
              Object.prototype.hasOwnProperty.call(instance, property) &&
              Object.prototype.hasOwnProperty.call(
                this.inventoryLabResult,
                property
              )
            ) {
              this.inventoryLabResult[property] = instance[property];
            }
          }
          this.loaded = true;
        });
      } else {
        this.loaded = true;
      }
      this.setDependencies();
    },
    setDependencies() {},
  },
  components: {},
};
</script>
